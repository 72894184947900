var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    { staticClass: "groupOrder" },
    [
      _c(
        "Col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.expand,
              expression: "expand",
            },
          ],
          style: _vm.leftSpan,
        },
        [
          _c(
            "Card",
            {
              staticStyle: { height: "calc(100vh - 80px)", overflow: "hidden" },
            },
            [
              _c("Alert", { staticClass: "ivu-alert" }, [_vm._v("团检订单")]),
              _c(
                "Row",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "RadioGroup",
                    {
                      staticStyle: {
                        width: "55%",
                        "margin-top": "10px",
                        "margin-left": "5px",
                      },
                      on: { "on-change": _vm.search },
                      model: {
                        value: _vm.searchForm.payStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "payStatus", $$v)
                        },
                        expression: "searchForm.payStatus",
                      },
                    },
                    [
                      _c("Radio", { attrs: { label: 0 } }, [_vm._v("未缴费")]),
                      _c("Radio", { attrs: { label: 1 } }, [_vm._v("已缴费")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Row",
                {
                  staticStyle: {
                    "margin-top": "10px",
                    "margin-bottom": "10px",
                  },
                },
                [
                  _vm.queryTime
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-wrap": "nowrap",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "RadioGroup",
                            {
                              staticClass: "btngroup",
                              attrs: {
                                type: "button",
                                size: "small",
                                "button-style": "solid",
                              },
                              on: { "on-change": _vm.radioChange },
                              model: {
                                value: _vm.radioDateValue,
                                callback: function ($$v) {
                                  _vm.radioDateValue = $$v
                                },
                                expression: "radioDateValue",
                              },
                            },
                            [
                              _c("Radio", { attrs: { label: "当日" } }),
                              _c("Radio", { attrs: { label: "当月" } }),
                              _c(
                                "DatePicker",
                                {
                                  attrs: {
                                    open: _vm.datePickerShow,
                                    transfer: "",
                                    type: "daterange",
                                  },
                                  on: { "on-change": _vm.datePickerChange },
                                },
                                [
                                  [
                                    _c("Radio", {
                                      attrs: { label: "自定义" },
                                      nativeOn: {
                                        click: function ($event) {
                                          _vm.datePickerShow =
                                            !_vm.datePickerShow
                                        },
                                      },
                                    }),
                                  ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "resetBtn",
                              on: { click: _vm.resetClick },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-wrap": "nowrap",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("DatePicker", {
                            attrs: {
                              transfer: "",
                              placement: "bottom-end",
                              transfer: "",
                              placeholder: "开始时间",
                              type: "date",
                            },
                            on: { "on-change": _vm.datePickerStartTime },
                            model: {
                              value: _vm.searchForm.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "startDate", $$v)
                              },
                              expression: "searchForm.startDate",
                            },
                          }),
                          _vm._v("  ~  "),
                          _c("DatePicker", {
                            attrs: {
                              transfer: "",
                              type: "date",
                              placeholder: "结束时间",
                            },
                            on: { "on-change": _vm.datePickerEndTime },
                            model: {
                              value: _vm.searchForm.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "endDate", $$v)
                              },
                              expression: "searchForm.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                ]
              ),
              _c(
                "Row",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c("Input", {
                    attrs: {
                      suffix: "ios-search",
                      placeholder: "请输入关键字",
                    },
                    on: { "on-change": _vm.search },
                    model: {
                      value: _vm.searchForm.searchKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "searchKey", $$v)
                      },
                      expression: "searchForm.searchKey",
                    },
                  }),
                ],
                1
              ),
              _c("Row", { staticStyle: { "margin-top": "10px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-wrap": "nowrap",
                      "align-items": "center",
                    },
                  },
                  [
                    _c("RadioGroup", {
                      staticClass: "btngroup",
                      attrs: {
                        type: "button",
                        size: "small",
                        "button-style": "solid",
                      },
                      on: { "on-change": _vm.radioChange },
                      model: {
                        value: _vm.radioDateValue,
                        callback: function ($$v) {
                          _vm.radioDateValue = $$v
                        },
                        expression: "radioDateValue",
                      },
                    }),
                    !_vm.queryTime
                      ? _c(
                          "span",
                          {
                            staticClass: "resetBtn",
                            on: { click: _vm.resetClick },
                          },
                          [_vm._v("重置")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "Row",
                { staticClass: "left-item" },
                [
                  _vm.groupOrderList.length > 0
                    ? _c(
                        "RadioGroup",
                        {
                          staticClass: "radio-group",
                          model: {
                            value: _vm.radioValue,
                            callback: function ($$v) {
                              _vm.radioValue = $$v
                            },
                            expression: "radioValue",
                          },
                        },
                        _vm._l(_vm.groupOrderList, function (item, index) {
                          return _c(
                            "Row",
                            {
                              key: item.id,
                              staticClass: "row-border",
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.radioChooseClick(item)
                                },
                              },
                            },
                            [
                              _c(
                                "Col",
                                {
                                  staticClass: "radio-group-radio",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c("Radio", {
                                    staticClass: "label",
                                    attrs: { label: item.id },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                {
                                  staticClass: "radio-group-content",
                                  attrs: { span: 21 },
                                },
                                [
                                  _c(
                                    "Row",
                                    { staticStyle: { "margin-bottom": "6px" } },
                                    [
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "date",
                                          attrs: { span: 20 },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatTime(item.signingTime)
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("Col", { attrs: { span: 4 } }, [
                                        item.physicalType.indexOf("职业") != -1
                                          ? _c(
                                              "div",
                                              { staticClass: "border-blue" },
                                              [_vm._v(" 职 ")]
                                            )
                                          : _vm._e(),
                                        item.physicalType.indexOf("健康") != -1
                                          ? _c(
                                              "div",
                                              { staticClass: "border-green" },
                                              [_vm._v(" 健 ")]
                                            )
                                          : _vm._e(),
                                        item.physicalType.indexOf("从业") != -1
                                          ? _c(
                                              "div",
                                              { staticClass: "border-yellow" },
                                              [_vm._v(" 从 ")]
                                            )
                                          : _vm._e(),
                                        item.physicalType.indexOf("放射") != -1
                                          ? _c(
                                              "div",
                                              { staticClass: "border-purple" },
                                              [_vm._v(" 放 ")]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Row",
                                    [
                                      _c(
                                        "Col",
                                        {
                                          staticClass: "company",
                                          attrs: { span: 20 },
                                        },
                                        [_vm._v(_vm._s(item.groupUnitName))]
                                      ),
                                      item.auditState == 4
                                        ? _c(
                                            "Col",
                                            {
                                              staticClass: "bg-green",
                                              attrs: { span: 4 },
                                            },
                                            [_vm._v("通")]
                                          )
                                        : _vm._e(),
                                      item.auditState == 1 ||
                                      item.auditState == 2 ||
                                      item.auditState == 3
                                        ? _c(
                                            "Col",
                                            {
                                              staticClass: "bg-audit",
                                              attrs: { span: 4 },
                                            },
                                            [_vm._v("审 ")]
                                          )
                                        : _vm._e(),
                                      item.auditState == 5
                                        ? _c(
                                            "Col",
                                            {
                                              staticClass: "bg-refute",
                                              attrs: { span: 4 },
                                            },
                                            [_vm._v("驳")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c("no-data"),
                  _vm.loading
                    ? _c(
                        "Spin",
                        { attrs: { fix: "" } },
                        [
                          _c("Icon", {
                            staticClass: "demo-spin-icon-load",
                            attrs: { type: "ios-loading", size: "18" },
                          }),
                          _c("div", [_vm._v("加载中...")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.groupOrderList.length > 0
                    ? _c("Page", {
                        attrs: {
                          current: _vm.searchForm.pageNumber,
                          total: _vm.total,
                          simple: "",
                        },
                        on: { "on-change": _vm.personChangePageNum },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "expand" },
        [
          _c("Icon", {
            staticClass: "icon",
            attrs: { type: _vm.expandIcon, size: "16" },
            on: { click: _vm.changeExpand },
          }),
        ],
        1
      ),
      _c(
        "Col",
        { style: _vm.span },
        [
          _c(
            "Card",
            { staticStyle: { height: "calc(100vh - 80px)", overflow: "auto" } },
            [
              _c("div", { staticStyle: { "margin-bottom": "10px" } }),
              _c(
                "Form",
                {
                  ref: "groupOrderForm",
                  attrs: {
                    model: _vm.groupOrderForm,
                    rules: _vm.groupOrderFormRule,
                    "label-width": 110,
                  },
                },
                [
                  _c(
                    "Row",
                    [
                      _c(
                        "Col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "团检单号", prop: "orderCode" } },
                            [
                              _c(
                                "Input",
                                {
                                  attrs: {
                                    type: "text",
                                    placeholder: "提交后系统自动生成",
                                    clearable: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.groupOrderForm.orderCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupOrderForm,
                                        "orderCode",
                                        $$v
                                      )
                                    },
                                    expression: "groupOrderForm.orderCode",
                                  },
                                },
                                [
                                  _vm.groupOrderForm.payStatus == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { color: "#19be6b" },
                                          attrs: { slot: "append" },
                                          slot: "append",
                                        },
                                        [_vm._v("已确认")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: { color: "red" },
                                          attrs: { slot: "append" },
                                          slot: "append",
                                        },
                                        [_vm._v("未确认")]
                                      ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: { label: "团检单位", prop: "groupUnitId" },
                            },
                            [
                              _c(
                                "Select",
                                {
                                  attrs: {
                                    "label-in-value": "",
                                    filterable: "",
                                    clearable: "",
                                    disabled:
                                      _vm.searchForm.payStatus == 1
                                        ? true
                                        : false,
                                  },
                                  on: { "on-change": _vm.groupUnitChange },
                                  model: {
                                    value: _vm.groupOrderForm.groupUnitId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupOrderForm,
                                        "groupUnitId",
                                        $$v
                                      )
                                    },
                                    expression: "groupOrderForm.groupUnitId",
                                  },
                                },
                                _vm._l(_vm.groupUnitList, function (item) {
                                  return _c(
                                    "Option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        label: item.name,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "销售负责人" } },
                            [
                              _c(
                                "Select",
                                {
                                  attrs: { "label-in-value": "" },
                                  on: { "on-change": _vm.saleManChange },
                                  model: {
                                    value: _vm.groupOrderForm.salesDirector,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupOrderForm,
                                        "salesDirector",
                                        $$v
                                      )
                                    },
                                    expression: "groupOrderForm.salesDirector",
                                  },
                                },
                                _vm._l(_vm.userList, function (item) {
                                  return _c(
                                    "Option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(_vm._s(item.nickname) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: { label: "签订日期", prop: "signingTime" },
                            },
                            [
                              _c("DatePicker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "请选择签订日期",
                                  format: "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.groupOrderForm.signingTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.groupOrderForm,
                                      "signingTime",
                                      $$v
                                    )
                                  },
                                  expression: "groupOrderForm.signingTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "体检日期",
                                prop: "deliveryTime",
                              },
                            },
                            [
                              _c("DatePicker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "请选择体检日期",
                                  format: "yyyy-MM-dd",
                                },
                                model: {
                                  value: _vm.groupOrderForm.deliveryTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.groupOrderForm,
                                      "deliveryTime",
                                      $$v
                                    )
                                  },
                                  expression: "groupOrderForm.deliveryTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "零星体检",
                                prop: "sporadicPhysical",
                              },
                            },
                            [
                              _c(
                                "Select",
                                {
                                  attrs: {
                                    disabled: _vm.searchForm.payStatus == 1,
                                    "label-in-value": "",
                                  },
                                  on: { "on-change": _vm.sporadicChange },
                                  model: {
                                    value: _vm.groupOrderForm.sporadicPhysical,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupOrderForm,
                                        "sporadicPhysical",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "groupOrderForm.sporadicPhysical",
                                  },
                                },
                                _vm._l(_vm.typeList, function (item) {
                                  return _c(
                                    "Option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(_vm._s(item.type) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "FormItem",
                            {
                              attrs: {
                                label: "是否需要合同评审",
                                prop: "isAuditState",
                                "label-width": 162,
                              },
                            },
                            [
                              _c(
                                "RadioGroup",
                                {
                                  on: { "on-change": _vm.auditStateChange },
                                  model: {
                                    value: _vm.groupOrderForm.isAuditState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupOrderForm,
                                        "isAuditState",
                                        $$v
                                      )
                                    },
                                    expression: "groupOrderForm.isAuditState",
                                  },
                                },
                                [
                                  _c(
                                    "Radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disabledAuditState,
                                        label: 0,
                                      },
                                    },
                                    [_vm._v("否")]
                                  ),
                                  _c(
                                    "Radio",
                                    {
                                      attrs: {
                                        disabled: _vm.disabledAuditState,
                                        label: 1,
                                      },
                                    },
                                    [_vm._v("是")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.groupOrderForm.payStatus == 0 && _vm.groupOrderForm.id
                ? _c(
                    "Button",
                    {
                      attrs: {
                        loading: _vm.saveLoading,
                        type: "success",
                        icon: "md-build",
                      },
                      on: {
                        click: function ($event) {
                          _vm.paymentVisible = true
                        },
                      },
                    },
                    [_vm._v(" 订单收费 ")]
                  )
                : _vm._e(),
              _c(
                "Button",
                {
                  staticStyle: { width: "110px" },
                  attrs: { type: "success", icon: "ios-clipboard" },
                  on: { click: _vm.printReceipt },
                },
                [_vm._v("打印缴费单")]
              ),
              _c(
                "Row",
                { attrs: { gutter: 5 } },
                [
                  _c(
                    "Col",
                    { attrs: { span: "24" } },
                    [
                      _vm.groupData.length > 0
                        ? _c(
                            "div",
                            [
                              _vm.groupData.length > 0
                                ? _c(
                                    "Alert",
                                    {
                                      staticClass: "ivu-alert-left",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [_vm._v(" 体检人员 ")]
                                  )
                                : _vm._e(),
                              _vm.groupData.length > 0
                                ? _c("Table", {
                                    ref: "tablePerson",
                                    attrs: {
                                      loading: _vm.maleLoading,
                                      border: "",
                                      "max-height": "450",
                                      columns: _vm.maleColumns,
                                      sortable: "custom",
                                      data: _vm.groupPersonData,
                                    },
                                  })
                                : _vm._e(),
                              _vm.groupData.length > 0
                                ? _c(
                                    "Row",
                                    {
                                      staticClass: "page",
                                      attrs: { type: "flex", justify: "end" },
                                    },
                                    [
                                      _c("Page", {
                                        attrs: {
                                          current:
                                            _vm.groupPersonSearchForm
                                              .pageNumber,
                                          total: _vm.groupPersonTotal,
                                          "page-size":
                                            _vm.groupPersonSearchForm.pageSize,
                                          transfer: "",
                                          "page-size-opts": [10, 20, 50],
                                          size: "small",
                                          "show-total": "",
                                          "show-elevator": "",
                                          "show-sizer": "",
                                        },
                                        on: {
                                          "on-change":
                                            _vm.groupPersonChangePageNum,
                                          "on-page-size-change":
                                            _vm.groupPersonPageSize,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "Form",
                                {
                                  staticStyle: { "margin-top": "20px" },
                                  attrs: { "label-width": 90 },
                                },
                                [
                                  false
                                    ? _c(
                                        "Row",
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: "4" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "套餐小计",
                                                    "label-width": 70,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("Icon", {
                                                        staticStyle: {
                                                          color: "#2d8cf0",
                                                        },
                                                        attrs: {
                                                          type: "md-arrow-forward",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "5" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "套餐原价：",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " ￥" +
                                                      _vm._s(
                                                        this.groupOrderForm
                                                          .packagePrice
                                                      ) +
                                                      "元 "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "5" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "套餐折扣：",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " ￥" +
                                                      _vm._s(
                                                        this.groupOrderForm
                                                          .packageDiscount
                                                      ) +
                                                      "元 "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "5" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "套餐合计：",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " ￥" +
                                                      _vm._s(
                                                        this.groupOrderForm
                                                          .packageTotal
                                                      ) +
                                                      "元 "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  Number(this.groupOrderForm.orderTotal) > 0
                                    ? _c(
                                        "Row",
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: "5" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: {
                                                    label: "订单合计：",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "font-size": "18px",
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "￥" +
                                                          _vm._s(
                                                            this.groupOrderForm
                                                              .orderTotal
                                                          ) +
                                                          "元"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("no-data", {
                            attrs: { width: "150", height: "150" },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("previewTemplate", {
        attrs: {
          groupOrderInfo: JSON.parse(JSON.stringify(_vm.groupOrderForm)),
        },
        model: {
          value: _vm.previewShow,
          callback: function ($$v) {
            _vm.previewShow = $$v
          },
          expression: "previewShow",
        },
      }),
      _c("contract-preview", {
        attrs: {
          groupOrderInfo: JSON.parse(JSON.stringify(_vm.groupOrderForm)),
          url: _vm.groupOrderForm.orderPath,
        },
        model: {
          value: _vm.showOrderContract,
          callback: function ($$v) {
            _vm.showOrderContract = $$v
          },
          expression: "showOrderContract",
        },
      }),
      _c("previewTemplate", {
        attrs: {
          groupOrderInfo: JSON.parse(JSON.stringify(_vm.groupOrderForm)),
        },
        model: {
          value: _vm.showOrderPreview,
          callback: function ($$v) {
            _vm.showOrderPreview = $$v
          },
          expression: "showOrderPreview",
        },
      }),
      _c("payment-receipt", {
        ref: "paymentReceipt",
        attrs: { data: _vm.groupData, payType: 1 },
        model: {
          value: _vm.printSheet,
          callback: function ($$v) {
            _vm.printSheet = $$v
          },
          expression: "printSheet",
        },
      }),
      _c("addPayment", {
        attrs: { modalTitle: "体检结算" },
        model: {
          value: _vm.paymentVisible,
          callback: function ($$v) {
            _vm.paymentVisible = $$v
          },
          expression: "paymentVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }